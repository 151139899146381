.homePage {
  box-sizing: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  .homePage__header {
    width: 100%;
    height: 82px;
    z-index: 99;
    color: #fff;
    overflow: hidden;

    .homePage__headermain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      height: 100%;
    }

    .homePage__webHeader {
      .homePage__anchor {
        // min-width: 600px;
        max-width: 800px;
        font-size: 16px;
        font-weight: 700;

        .header__menu {
          display: flex;
          list-style: none;
          align-items: center;
          height: 82px;

          li {
            height: 82px;
            display: flex;
            align-items: center;

            .menu__link {
              color: inherit;
              /* 继承父元素的文本颜色 */
              text-decoration: none;
              /* 移除下划线样式 */
              cursor: pointer;
              /* 光标样式为默认指针 */
              background-color: transparent;
              /* 背景色为透明 */
              font: inherit;
              /* 继承父元素的字体 */
              padding: 0 1em;
              /* 清除内边距 */
              margin: 0;
              /* 清除外边距 */
              border: none;
              /* 清除边框 */
              outline: none;
              /* 清除轮廓 */
              transition: color 0.3s;
            }

            .menu__link:hover {
              color: #f08a26;
              /* 悬停时改变颜色 */
            }
          }
        }
      }
    }

    .homePage__mobileHeader {
      .homePage__logo{
        padding-left: 20px;
      }
      .homePage__anchor{
        padding-right: 20px;
        .anticon-menu{
          font-size: 20px;
          color: #f08a26;
        }
      }
    }
    .homePage__showAffix{
      position: fixed;
      height: 82px;
      width: 100%;
      top: 0;
      background: #fff;
    }
  }

  .homePage__content {
    overflow: hidden;
    position: absolute;
    width: 100%;

    .content__video {
      max-width: 100vw;
      width: 100vw;
      height: 100vh;
      color: #fff;
      letter-spacing: 0.2em;
      font-size: 30px;
      background: rgba(0,0,0,.36);

      .video__box {
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: 0;
        line-height: 1;
        border: none;
        display: inline-block;
        vertical-align: baseline;
        -o-object-fit: cover;
        background-size: cover;
        object-fit: cover;
      }

      .video__mark {
        position: relative;
        top: -60%;
        box-shadow: 0 0 #00000070;
        row-gap: 20px;
        column-gap: 20px;
        overflow: visible;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        max-width: 100%;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        text-align: center;
        h1{
          font-size: 50px;
          h3{
            font-size: 20px;
          }
        }
      }
    }

    .about__content {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box;

      .about__box {
        max-width: 1200px;
        margin: auto;
        text-align: center;
        h2 {
          color: #f08a26;
          font-size: 30px;
        }

        p {
          color: #94a3b8;
          font-size: 16px;
        }

        .has__text__color {
          color: #334155;
          overflow-wrap: break-word;
        }

        .Image__row__span {
          font-size: 16px;
          // display: block;
        }

        .aboutUs {
          // display: flex;
          // flex-wrap: wrap;
          align-items: center;

          .aboutUs__bcakground {
            box-shadow: 0 50px 50px -30px rgba(0, 0, 0, .15);
            padding: 10px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            background-image:
              linear-gradient(to right, rgba(255, 255, 255, .94),
                rgba(255, 255, 255, .94)),
              url('../../static/about_background.jpg');

            .aboutUs__bcakground__fig {
              width: 100%;
              margin: 0 0 1em;
              ;

              .aboutUs__bcakground__fig__imgg {
                max-width: 300px;
              }

              .aiDefi1__img {
                width: 100%;
              }
            }
          }

          .aboutUs__text {
            text-align: left;
          }

          .box-aling-center {
            display: flex;
            align-items: center;
          }
        }

        .tellUs__box {
          text-align: left;
          font-size: 16px;

          div {
            margin-bottom: 5px;

            .tellIcon {
              color: #f08a26;
              margin-right: 5px;
              font-size: 19px;
            }
          }
        }
      }
      .textLeft{
        text-align: left;
      }
    }
    .echartsBox{
      background-image: linear-gradient(21deg,#010101 21%,#94a3b8 94%);
      .echartsBox__div{
        max-width: 1248px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 80%;
        .echartsBox__div__earthDemo{
        }
        .earth__font__box{
          color: #fff;
          overflow-wrap: break-word;
          text-align: left;
          padding-right: 30px;
        }
      }
    }

    .carousel-item {
      .carousel-item-img {
        padding: 0 5px;
      }
    }
    .AffixIcon{
      color: #f08a26;
      font-size: 50px;
    }

    .homePage__footer {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1e293b;
      color: #cbd5e1;
    }
  }
  .huiBackground{
    background-color: #e2e8f0;
  }

}

.web__content__width {
  max-width: 1240px;
}

.DropdownBox {
  background-color: rgba(0, 0, 0, 0.28);
  font-size: 16px;
  font-weight: 700;
  border-top: 2px solid #f08a26;
  color: #fff;

  .dropdownRender__box {
    .dropdownRender__content {
      width: 200px;
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }
  }
}
.DropdownBox__mobile{
  .dropdownRender__content {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .last-child-order>div:last-child {
    order: -1;
    /* 将末尾元素放置在第一行 */
  }
}
@media screen and (max-width: 768px) {
  .earth__font__box {
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: 20px;
  left: -20px;
  z-index: 2;
  color: #007cba;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: 20px;
  right: -20px;
  z-index: 2;
  color: #007cba;
}

.closeSpan{
  width: 200px;
  height: 200px;
}



.DrawerBox{
  .CloseBtn{
    position: absolute;
    right: 20px;
  }
  .header__menu{
    list-style: none;
    padding: 0;
    li {
      display: flex;
        font-size: 16px;
        line-height: 40px;
      .menu__link {
        padding: 20px 0;
        font-size: 16px;
        line-height: 40px;
        color: inherit;
        /* 继承父元素的文本颜色 */
        text-decoration: none;
        /* 移除下划线样式 */
        cursor: pointer;
        /* 光标样式为默认指针 */
        background-color: transparent;
        /* 背景色为透明 */
        font: inherit;
        /* 继承父元素的字体 */
        margin: 0;
        /* 清除外边距 */
        border: none;
        /* 清除边框 */
        outline: none;
        /* 清除轮廓 */
        transition: color 0.3s;
      }

      .menu__link:hover {
        color: #f08a26;
        /* 悬停时改变颜色 */
      }
    }
    .DropdownBox__mobile__li{
      display: flex;
    width: 100%;
    justify-content: space-between;
    line-height: 58px;
      // justify-content: space-between;
      // align-items: center;
    }
  }
}
.DropdownBox__mobile {
  font-size: 16px;
  font-weight: 400;
  // width: 100%;
}
